@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  
}


